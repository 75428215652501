








































































































































>>> .v-input__slot::before {
	border-style: none !important;
}
.active-input {
	background: var(--v-primary-lighten5) !important;
	&.v-text-field input {
		color: var(--v-primary-base) !important;
	}
}
.readonly-input:hover {
	@extend .active-input;
}
