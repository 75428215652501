































































@import 'vue-apps-assets/styles/scss/vars.scss';
.progress {
	height: 2rem;
	width: 100%;
	background-color: $border-color;
	position: relative;
	#simple-progressbar-text {
		color: #FFF;
		position: absolute;
		text-align: center;
		top: 0.5rem;
		left: 0;
		right: 0;
	}
}
.progress .loader {
	margin: 0;
	display: flex;
	justify-content: space-arround;
}
.smooth-transition {
	transition: width 1s ease;
}
.stop-animation {
	 &:before {
		background: none;
		animation: none;
	}
}

.loader {
   height: 2rem;
}
