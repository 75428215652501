/* Fonts */
@import url("https://use.typekit.net/sdx2qsy.css");
.dev {
  width: 100%;
  background: red;
  color: #fff;
  display: block;
  padding: 5px;
  font-size: 10px;
  text-transform: uppercase;
}
/* Global Styles */
.pc-ns {
  flex-grow: 1;
  font-family: Montserrat;
  background-color: #fff;
  padding: 0px 20px;
}
.pc-ns a {
  transition: all 0.3s ease;
  color: #3fae29;
}
.pc-ns a:focus,
.pc-ns button:focus,
.pc-ns input:focus,
.pc-ns textarea:focus,
.pc-ns label:focus,
.pc-ns .rc-slider-handle:focus,
.pc-ns select:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #ddd !important;
}
.pc-ns .show {
  display: block !important;
  visibility: visible !important;
}
.pc-ns .hidden {
  display: none !important;
  visibility: hidden !important;
}
.pc-ns .number-search-wrapper {
  /* margin: 15px; */

  max-width: 960px;
  /* min-width: 720px; */
  margin: auto;
  padding: 0px 15px 15px 15px;
}

@media (min-width: 1500px) {
  .pc-ns .number-search-wrapper {
    max-width: none;
    margin: auto;
    padding: 0px 75px;
  }
}

@media (max-width: 1500px) {
  .pc-ns {
    overflow-x: scroll !important;
  }
}

/* Animations */
@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(-65px);
  }
  50% {
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.purchasing-wrapper {
  height: 55vh;
  position: relative;
}