/* Selected Numbers */
.numbers {
  position: relative;
  width: 100%;
  border-top: 1px solid #eee;
}

.numbers-list {
  padding: 0;
  list-style: none;
  margin: 0 auto;
  width: 100%;
  max-height: 100px;
  overflow: scroll;
  justify-content: center;
}

.numbers li {
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 7px 15px 7px 15px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  /* font-family: roboto-mono, monospace; */
  background: #48B9D5;
  width: 218px;
}
.numbers li .alpha {
  color: white;
  /* font-family: roboto-mono, monospace; */
}
.numbers li .price {
  margin-left: auto;
  font-size: 14px;
  color: white;
  font-weight: 400;
  font-family: NTR;
}

.numbers li .price-free {
  font-size: 14px;
  color: white;
  font-weight: 400;
  /* font-family: Proxima-Nova; */
  margin-left: auto;
  font-family: NTR;
}

.numbers li .remove-number {
  /* background-color: #333; */
  font-weight: 600;
  color: #fff !important;
  cursor: pointer;
  margin-left: 10px;
}
/*.numbers li .remove-number:hover {
  background: #3fae29;
  color: #fff;
} */
.reserved-number {
  font-weight: 500;
  position: relative;
  color: white;
  font-family: Montserrat;
}

.search-result {
  margin: 5px;
}


@media only screen and (max-height: 768px) {
  .numbers-list {
    padding-bottom: 40px !important;
  }
}

@media only screen and (max-width: 500px) {
  .numbers {
    display: none;
  }
  .numbers-list {
    display: none;
  }

}