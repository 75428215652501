


























































































































































































































.mobile-apps-container {
  width: 520px;
  .os-container {
    height: 128px;
    width: 250px;
    background: white;
    border: 1px solid rgba(133, 146, 154, 0.25);
    border-radius: 10px;
    margin-bottom: 40px;
    &:not(:first-child) {
      margin-left: 20px;
    }
    .os-image-container {
      height: 98px;
      display: flex;
      justify-content: space-around;
      padding-top: 20px;
    }
    .send-link-by-sms {
      background: var(--v-primary-base);
      cursor: pointer;
      color: white;
      height: 34px;
      align-items: center;
      border-radius: 0 0 10px 10px;
      padding: 8px 0 8px 15px !important;
      &.disabled-send-sms {
        background: var(--v-action-lighten2);
        color: var(--v-text-lighten3);
      }
    }
  }
  .apps-credentials {
    display: flex;
    margin-bottom: 15px;
    .selector-wrapper {
      width: 300px;
    }
    .credentials-label {
      width: 115px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
