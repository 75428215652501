



















































































































.theme--light.v-file-input .v-file-input__text {
  color: white !important;
}
.v-text-field__slot input {
   color: red
 }
.v-file-input {
  max-width: 100px;
  border-bottom: 0;
}
#file {}
