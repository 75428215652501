


























































































































.device-buy-payment-method {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .cards-container {
        .step-title {
            margin-bottom: 30px;
        }
    }
    .add-card-btn-container {
        width: 350px;
        margin: 0 auto;
        text-align: left;
    }
}
