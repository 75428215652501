































































































































@import 'vue-apps-assets/styles/scss/styles.scss';
#app {
  min-width: 30vw !important;
  min-height: 30vh !important;
}
