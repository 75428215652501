



















































































































































































































































































































































































































































































































































































































































































































































































































.visualizerClass {
  height: 100%;
  margin-top: -15px;
}
.visualizerClass::v-deep .v-input__slot {
  background-color: white !important;
}
.block-container {
  position: fixed;
  top: 65px;
  left: 222px;
  height: 100%;
  padding-left: 0px;
  width: 100%;
  flex-grow: 1;
  margin-top: -8px;
  @media only screen and (max-width: 961px) {
    left: 0px;
  }
}
.callFlowHeading {
  font-weight: 600;
  margin-top: 5px;
  font-size: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #197F9F;
}
.callFlowCol {
  display: flex;
  align-items: center;
}
.visualizer-container {
  display: flex;
  flex-direction: column;
  // margin-left: 29px;
}
.top-row {
  flex-shrink: 0;
  background-color: white;
  width: max-content;
  z-index: 2;
  border-radius: 9px;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
}
.object-type {
  max-width: 200px;
}
.center-autocomplete {
  margin-top: -6px;
}
.dynamic-width-autocomplete {
  width: auto !important;
}
.chosenItemCol {
  width: 360px;
}
.backButton {
  margin-top: -4px;
  font-weight: 600 !important;
  font-size: 10px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px !important;
  text-transform: uppercase !important;
  color: #197F9F !important;
}
