






































$arrow-height: 16;
$arrow-width: 16;
.custom-tooltip {
	border-radius:  2px;
	opacity:  1 !important;
	font-size:  11px !important;
	line-height:  16px !important;
	letter-spacing: 0.1px !important;
	padding:  14px 16px;
	&.small {
		padding:  7px 12px !important;
	}
}

.tooltip-top::before {
    border-right: solid 8px transparent;
    border-left: solid 8px transparent;
    transform: translateX(-50%);
    position: absolute;
    z-index: -21;
    content: '';
    top: 100%;
    left: 50%;
    height: 0;
    width: 0;
}
.tooltip-top.error::before{
    border-top: solid 8px var(--v-error-base);
}

.tooltip-top.success::before{
    border-top: solid 8px var(--v-success-base);
}
.tooltip-top.secondary::before{
    border-top: solid 8px var(--v-secondary-base);
}

.tooltip-top.custom-tooltip::before{
    border-top: solid 8px var(--v-text-base);
}

.tooltip-top.info::before{
    border-top: solid 8px var(--v-info-base);
}

.tooltip-bottom::before{
    border-right: solid 8px transparent;
    border-left: solid 8px transparent;
    transform: translateX(-50%);
    position: absolute;
    z-index: -21;
    content: '';
    bottom: 100%;
    left: 50%;
    height: 0;
    width: 0;
}

.tooltip-bottom.error::before{
    border-bottom: solid 8px var(--v-error-base);
}

.tooltip-bottom.success::before{
    border-bottom: solid 8px var(--v-success-base);
}
.tooltip-bottom.secondary::before{
    border-bottom: solid 8px var(--v-secondary-base);
}

.tooltip-bottom.custom-tooltip::before{
    border-bottom: solid 8px var(--v-text-base);
}

.tooltip-bottom.info::before{
    border-bottom: solid 8px var(--v-info-base);
}

.tooltip-right::before{
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -8px;
    border-width: 8px;
    border-style: solid;
    border-top: solid 8px transparent;
    border-bottom: solid 8px transparent;
    border-left: solid 8px transparent;
}

.tooltip-right.error::before{
    border-right: solid 8px var(--v-error-base) !important;
}

.tooltip-right.success::before{
    border-right: solid 8px var(--v-success-base) !important;
}
.tooltip-right.secondary::before{
    border-right: solid 8px var(--v-secondary-base) !important;
}

.tooltip-right.custom-tooltip::before{
    border-right: solid 8px var(--v-text-base) !important;
}

.tooltip-right.info::before{
    border-right: solid 8px var(--v-info-base) !important;
}

.tooltip-left::before{
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the left of the tooltip */
    margin-top: -8px;
    border-width: 8px;
    border-style: solid;
    border-top: solid 8px transparent;
    border-bottom: solid 8px transparent;
    border-right: solid 8px transparent;
}

.tooltip-left.error::before{
    border-left: solid 8px var(--v-error-base) !important;
}

.tooltip-left.success::before{
    border-left: solid 8px var(--v-success-base) !important;
}
.tooltip-left.secondary::before{
    border-left: solid 8px var(--v-secondary-base) !important;
}

.tooltip-left.custom-tooltip::before{
    border-left: solid 8px var(--v-text-base) !important;
}

.tooltip-left.info::before{
    border-left: solid 8px var(--v-info-base) !important;
}
