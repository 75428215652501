























































































































































































































































































































































































































	@import 'vue-apps-assets/styles/scss/vars.scss';

  svg {
      width: 100%;
  }
  .state {
      fill: $bg;
  }
  .borders {
      stroke: #fff;
      stroke-width: 1px;
  }
  .separator1 {
      stroke: $border-color;
      stroke-width: 1px;
  }
