
















































































































































































































































.route-rule-filter {
  .v-input__icon--prepend {cursor: grab;}
}
.route-rule-title {
  border-top: thin solid var(--v-info-base);
  border-bottom: thin solid var(--v-action-lighten5);;
}
