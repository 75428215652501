







































































































































@import '~vuetify/src/styles/settings/_variables';
  .fixedHeight.v-input__slot {
    height: 40px !important;
    max-height: 40px !important;
  }
.sign-in-token {
  color: #29921B !important;
  float:right;
  margin-top: -30px !important;
  margin-right: 30px !important;
}
.tight-input {
  width: 250px;
}
.homepage {
  .v-text-field {
    margin-top: 0;
  }
  .homepage-container{
    margin-top: 143px;
    display: flex;
    background: #FFF;
    .image-container {
      width: 330px;
      height: 320px;
      border-radius: 24px;
    }
    .information-container {
        margin-top: 35px;
        margin-bottom: 5px;
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        margin-left: 10px;
        width: 572px;
      #version-and-powered {
        padding-left: 90px;
        font-size: 10px !important;
        color: var(--v-text-lighten1);
        line-height: 16px;
        .pdc {
          color: #29921b;
        }
      }
      .main-information {
        height: 250px;
        min-height: 250px;
        border-radius: 24px;
        width: 100%;
        height: 100%;
        padding-left: 90px;
        padding-top: 52px;
        .main-title {
          font-size: 30px;
        }
        .user-details {
          margin-top: 30px;
        }
      }
      .auth-container {
        height: 50px;
      }
      .sing-in-container {
        width: 150px !important;
      }
    }
  }
}
@media only screen and (max-width: #{map-get($grid-breakpoints, 'md')}) {
   .sign-in-token {
    float:none;
  }
  .fixedHeight.v-input__slot {
    height: 40px;
    max-height: 40px;
  }
  .homepage {
    .homepage-container{
      margin-top: 15px;
      .image-container {
        width: 300px;
        height: 320px;
      }
      .information-container {
        margin-top: 0;
        width: 100%;
        #version-and-powered {
          padding: 5px;
          font-size: 10px !important;
          color: var(--v-text-lighten1);
          line-height: 16px;
        }
        .main-information {
          background-image: none !important;
          padding: 5px;
          margin: 0 auto;
        }
      }
    }
  }
}
