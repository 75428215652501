



























































































































>>> .v-input__slot::before {
	border-style: none !important;
}
.close-time {
	width: 20px;
	max-width: 20px;
}
