




























































































.long-table {
    height: 40rem;
    max-height: 40rem;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(
		to bottom,
		rgba(0,0,0,1),
		rgba(0,0,0,1) 50%,
		rgba(0,0,0,1) 60%,
		rgba(0,0,0,0.9) 70%,
		rgba(0,0,0,0.7) 80%,
		rgba(0,0,0,0.4) 90%,
		rgba(0,0,0, 0) 100%
	);
}

#additional-button {
  display: block;
  position: fixed;
  bottom: 2rem;
  z-index: 99;
}
