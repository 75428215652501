




































































































































































































































































































	@import 'vue-apps-assets/styles/scss/vars.scss';

  .devices {
    .copy-cell {
      white-space: nowrap;
    }
    .list-table {
      .active-row {
        td {
          border-bottom: 0 !important;
          height: 28px;
        }
        .partially-bordered-td:before {
          border-left:1px solid var(--v-secondary-lighten1) !important;
          padding-top: 17px !important;
          padding-bottom: 17px !important;
        }
        &:last-child {
          td {
            border-bottom: 3px solid black !important;
          }
        }
      }
      .first-active-row {
        .partially-bordered-td:before {
          padding-top: 7px !important;
        }
      }
      .last-active-row {
        .partially-bordered-td:before {
          padding-bottom: 7px !important;
        }
        td {
          border-bottom: $table-border !important;
        }
      }
    }
  }
