



















































































































































































































	.custom-time-selector {
		&.v-text-field .v-input__append-inner {
			padding-left: 0 !important;
		}
		&.v-text-field input {
			padding-bottom: 10px !important;
			letter-spacing: 0px;
		}
		.v-input__icon .v-input__icon--append {
			margin-right: 0 !important;
		}
		.v-input__append-inner {
			.primary--text { // arrows goes out of the input box
				display: none !important;
			}
		}
	}
	.disabled {
        opacity: 0.5;
	}
