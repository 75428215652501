

























































































































    @import '~vuetify/src/styles/styles.sass';
	@import 'vue-apps-assets/styles/scss/vars.scss';

  .action-selector-container {
    .v-select, .v-icon {
      height: $input-height;
    }
  }
  .actions:not(:first-child) {
    padding-top: 30px;
  }
  .actions:first-child {
		padding-top: 12px;
  }
  .bottom-separator {
    padding-bottom: 30px;
    border-bottom: thin solid rgba(133, 146, 154, 0.2);
  }
