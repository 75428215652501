
























































.text-editor-wrapper {
  .ql-container {
    height: inherit !important;
  }
  .hidden-input {
    .v-input__slot {
      display: none !important;
    }
    .v-messages.error--text {
      margin-top: 8px;
    }
  }
  .has-error {
    background: var(--v-action-darken2) !important;
  }
}
