















.square {
    height: 1rem;
    width: 1rem;
    display: inline-block;
    vertical-align: middle;
}
