








































































































































































































.listener-filters {
  .listener-filters-wrapper {
    border: 1px solid rgba(133, 146, 154, 0.65);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
  }

  .number-filter-type {
    width: 199px;
  }
}
