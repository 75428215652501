















































































































































































.payment-methods-wrapper {
    width: 350px;
    margin-top: 50px;
    .credit-card-actions {
        span, .v-icon svg {
            color: var(--v-secondary-darken1);
        }
        &:hover {
            // color: var(--v-secondary-darken4);
            span, .v-icon svg {
                color: var(--v-secondary-darken4);

            }
        }
        span {
            cursor: pointer;
        }
        .action-text{
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
        .v-icon {
            margin-right: 3px;
            margin-top: -1px;
        }
    }
}
.no-card-container {
    margin-top: 38.5vh;
}
