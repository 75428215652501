

























































































input {
	text-align: center;
	.v-input__slot {
		padding-left: 0;
		padding-right: 0;
	}
}
