




















































































































































































































































































































.account-settings {
    .shorter-settings-label {
        width: 150px;
        margin-top: 16px;
    }
    .moh-advanced-label {
        @extend .shorter-settings-label;
        margin-top: 11px;
    }
    .recording-option {
        display: flex;
        margin-top: 9px;
        .custom-select {
            width: 300px;
        }
        .recording-helper-text {
            max-width: 375px;
            margin-top: 12px;
                margin-bottom: 12px;
        }
    }
    .premium-moh-selector {
        .type-radio-selector-helper-text {
            margin-top: -3px;
            margin-bottom: 11px;
            max-width: 325px;
        }
    }
    .selector-wrapper .v-input {
        width: 300px;
    }
}
