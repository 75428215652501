












































































































































































































.buy-device-shipping-details {
	width: 500px;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	.main-title {
		margin-bottom: 50px;
	}
	.content {
		width: 500px;
		max-width: 500px;
	}
}
