
















































































@import '~vuetify/src/styles/settings/_variables';
.filters-container, .test-number-container {
    margin-top: 15px;
}
#filters-buttons, .filters-buttons {
  margin-top: 43px;
  margin-bottom: 32px;
}
@media only screen and (min-width: #{map-get($grid-breakpoints, 'md')}) {
  $filters-label-width: 130px;
  $filters-input-width: 300px;
  .filters {
    .form-field-container {
      margin-left: auto;
      margin-right: auto;
      max-width: calc($filters-label-width + $filters-input-width);
      .form-input-label, .form-label {
        width: $filters-label-width;
        max-width: $filters-label-width;
      }
      .form-input {
        width: $filters-input-width;
        max-width: $filters-input-width;
      }
    }
    .large-filter-field {
      margin-bottom: 17px;
      .form-input {
        position: absolute;
        width: calc(730px - $filters-label-width);
        max-width: calc(730px - $filters-label-width);
        margin-left: $filters-label-width;
      }
    }
  }
}
