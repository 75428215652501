






































































































































@import 'vue-apps-assets/styles/scss/vars.scss';

.preset-switcher-list-item {
	background: $frame-background;
	height:  40px;
	padding: 10px 15px;
	font-size:  12px;
	cursor:  pointer;
	color:  var(--v-text-base);
}
.preset-switcher-list-item:hover {
	background:  var(--v-primary-lighten5);
}
