



















































































































































































































































































$blockBorder: 0px;

$ioPaddingInner: 2px 0;
$ioHeight: 21px;
$ioFontSize: 11px;

$circleBorder: 4px;
$circleSize: 12px;
$circleMargin: 2px; // left/right

$circleNewColor: #364047;;
$circleRemoveColor: #FF0000;
$circleConnectedColor: #FFFF00;

.vue-block {
  position: absolute;
  box-sizing: border-box;
  border: $blockBorder solid black;
  border-radius: 9px;
  color: white;
  border: 1px solid transparent;
  background: white;
  z-index: 1;
  opacity: 0.9;
  cursor: grab;

  &.selected {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 2;
  }

  > header {
    text-align: center;

    > .delete {
      color: white;
      cursor: pointer;
      float: right;
      position: absolute;
      right: 5px;
      text-decoration: none;
    }
  }
  .inputs, .outputs {
    padding: $ioPaddingInner;
    color: #364047;
    display: block;
    width: 50%;

    > * {
      width: 100%;
    }
  }

  .circle {
    box-sizing: border-box;
    margin-top: $ioHeight / 2 - $circleSize / 2 - 2;

    width: $circleSize;
    height: $circleSize;

    border: $circleBorder solid rgba(54, 64, 71, 1);
    border-radius: 100%;

    // cursor: crosshair;
    background-color: white;
  }

  .inputs {
    float: left;
    text-align: left;
    width: 8% !important;
    margin-left: -($circleSize/2 + $blockBorder);
  }

  .input, .output {
    height: $ioHeight;
    overflow: hidden;
    font-size: $ioFontSize;
  }

  .input {
    float: left;
    display: flex;
    align-items: center;

    .circle {
      float: left;
      margin-right: $circleMargin;
      margin-left: 2px;
      margin-top: 0px !important;
      width: 5px !important;
      height: 5px !important;

      &:hover {
        background: $circleNewColor;

        &.active {
          background: $circleRemoveColor !important;
        }
      }
    }
  }

  .outputs {
    float: right;
    text-align: right;
    width: 92% !important;
    display: flex;
    flex-direction: column;
    margin-right: -7px;
  }

  .output {
    float: right;

    .outputLabel {
      margin-right: 19px;
      max-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .circle {
      float: right;
      margin-left: $circleMargin;

      &:hover {
        background: $circleNewColor;
      }
    }
  }
}
.actionKey {
  display: inline-flex;
  border-radius: 3px;
  width: 18px;
  justify-content: center;
  color: #FFFFFF;
}
.queueKey {
  background: rgb(223, 192, 132);
}
.menuKey {
  background: #2A9BB7;
}
.svgIcon {
  vertical-align: middle;
  float: left;
  width: 15px;
  height: 15px;
  margin-bottom: 1px;
  margin-right: 5px;
  margin-left: 8px;
  color: #364047;
  z-index: -1;
}
.actionIcon {
  position: absolute;
  left: 25px;
}
.deviceIcon {
  position: absolute;
  width: 50px;
  height: 50px;
  margin-top: -34px;
  margin-left: -90px;
}
.actionType {
  margin-left: 28px;
}
.nameHolder {
  // height: 20px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menuNameHolder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 7px;
  max-width: 85%;
}
.menuRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.numberAndExtensionTitle {
  margin-top: -21px;
  margin-left: 4px;
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.numberAndExtensionHeader {
  color: #364047;
  background-color: transparent !important;
  font-weight: 500;
  font-size: 16px;
  width: 90% !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  height: 32px !important;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  float: right;
  border-bottom: none !important;
  margin-right: 9px;
  position: unset !important;
}
.outputsHeight {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.heightInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
}
.inputColor {
  background-color: #8C98A0 !important;
  border: none !important;
}
.deviceInput {
  margin-right: -2px !important;
}
.headerStyle {
  font-weight: 700;
  color: #364047;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: auto !important;
  border-bottom: none !important;
  text-align: center !important;
  background-color: transparent !important;
  position: unset !important;
}
.filterName {
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.leftSideLabel {
  text-align: left;
}
.backLink {
  border: 0.5px solid #8c98a0;
  position: absolute;
  padding-left: 3px;
  padding-right: 3px;
  width: 95px;
  max-height: 18px;
  border-radius: 14px 4px 4px 14px;
  margin-left: 211px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
