










































































































































































































































































































.a-lot { // TODO check more messages and a lot scroller (when has > 5)
  max-height: 30rem;
  overflow: scroll;
  overflow-x: auto;
}
.overflow-y-hidden .conversations {
  z-index: 0;
}
.conversations-checkbox {
  min-width: 35px;
}
