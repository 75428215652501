




















































































































































































































































































































































































































































































































































































.picker-tabs {
  .active-tab, .inactive-tab {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}
.area-code-input{
    max-width: 250px !important;
    margin-left: 10px !important;
}

