



























































































































































































































tr.unread {
  font-weight: bold;
}
.long-line {
  max-width: 30%;
}
