.slide-fade-enter-active, .slide-fade-right-left-enter-active {
    transition: all 0.6s ease;
}

.slide-fade-leave-active, .slide-fade-right-left-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter {
    transform: translateX(20px);
    opacity: 0;
}

.slide-fade-leave-to {
    transform: translateX(-20px);
    opacity: 0;
}

.slide-fade-right-left-enter {
    transform: translateX(-20px);
    opacity: 0;
}

.slide-fade-right-left-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

.collapsable-enter-active,
.collapsable-leave-active {
  transition: max-height .2s ease;
  max-height: 400px;
}

.collapsable-enter,
.collapsable-leave-to {
  max-height: 0;
  overflow: hidden;
}

.relative {
  position: relative;
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translate(30px, 0);
}
.list-leave-active {
  position: absolute;
}

.dropzone {
    background:  rgba(52, 57, 60, 0.1);
    box-shadow: 0 0 0 1rem rgba(52, 57, 60, 0.1);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.centered-input input {
  text-align: center !important;
}
.hide-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

// charts
#orange-charts-details {
    position: absolute;
    z-index: 2;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    width: 8rem;
    color: #ffffff;
    font-size: 0.85rem;
    text-shadow: 1px 1px 0 rgba(0,0,0,0.75);
    background: rgba(0,0,0,0.75);
    margin: 1rem 0 0 1rem;
    white-space: pre-line;
}

#pdf-export svg{
    max-width: 100%
}

#orange-charts-details:empty {
    display: none;
}

#stats-table {
    table-layout: fixed;
    tr {
        td {
            vertical-align: middle;
            &.disabled {
                color: gray;
            }
        }
    }
    thead {
      tr {
      th {
        width: 25% !important;
      }
          th:not(:first-child) {
              text-align: center;
          }
      }
    }
    tbody {
        tr:last-child {
            border-top: none;
            td:not(:first-child) {
                padding-top: 0.5rem;
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0.25rem;
                    left: 1rem;
                    right: 1rem;
                    border: 1px solid var(--v-text-base);
                    border-bottom: none;
                    height: 0.75rem;
                }
                span {
                    display: block;
                }
                .min {
                    margin-left: -0.2rem;
                }
                .max {
                    float: right;
                    margin-right: -0.2rem;
                }
            }
            & > &:last-child {
                padding-right: 0;
            }
        }
    }
}

.border-0 {
  border: 0px !important;
}

.rotate-180 {
    transform: rotate(180deg);
}

.w-letter-spacing-medium {
  letter-spacing: 0.75px !important;
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.w-90 {
  width: 90% !important;
  max-width: 90% !important;
}

.w-80 {
  width: 80% !important;
  max-width: 80% !important;
}

.w-70 {
  width: 70% !important;
  max-width: 70% !important;
}

.w-65 {
  width: 65% !important;
  max-width: 65% !important;
}

.w-60 {
  width: 60% !important;
  max-width: 60% !important;
}

.w-55 {
  width: 55% !important;
  max-width: 55% !important;
}

.w-50 {
  width: 50% !important;
  max-width: 50% !important;
}

.w-40 {
  width: 40% !important;
  max-width: 40% !important;
}

.w-45 {
  width: 45% !important;
  max-width: 45% !important;
}


.w-35 {
  width:35% !important;
  max-width:35% !important;
}

.w-30 {
  width:30% !important;
  max-width:30% !important;
}

.w-20 {
  width: 20% !important;
  max-width: 20% !important;
}

.w-15 {
  width: 15% !important;
  max-width: 15% !important;
}

.w-10 {
  width: 10% !important;
  max-width: 10% !important;
}

.max-width-600 {
  max-width: 600px;
}

.max-width-630 {
  max-width: 630px;
}

.m-top-50 {
  margin-top: 50px !important;
}

.m-bottom-50 {
  margin-bottom: 50px !important;
}

.m-bottom-0 {
  margin-bottom: 0 !important;
}

.m-left-45 {
  margin-left: 45px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-top-30 {
  margin-top: 30px;
}

.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}

.hide-scroller {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll; 

}
.hide-scroller::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.pre-line{
  white-space: pre-line;
}

.minimal-width {
  width: 1%;
}
.nowrap {
  white-space: nowrap;
}
.overflow-hidden {
  overflow: hidden;
}

.break-all {
  word-break: break-all !important;
}

.break-word {
  word-break: break-word !important;
}

.error-bg {
  background-color: $light-error;
}
.light-background {
  background: #EAF5F8;
}

@media print {
  .printable {
    overflow: visible !important;
  }
  .w-print-100 {
    width: 100% !important;
    min-width: 100% !important;
  }
  body {
    -webkit-print-color-adjust: exact;
  }
  #page-container {
    width: 100% !important;
    margin-left: 0 !important;
  }
  #sidebar-container {
    width: 0px;
  }
  #app #main-container > main {
    background: #FFF !important;
  }
  .vertical-tabs div {
    width: 100%;
  }
  #main-content main, #main-container{
    width: 100%;
    table {
      width: 100%;
    }
  }
}