













































@import 'vue-apps-assets/styles/scss/vars.scss';

.bar-wrapper {
	background: $bg;
	height: 2.2rem;
}

.bar {
	height: 100%;
	border-left: 0;
	transition: width 0.5s
}
